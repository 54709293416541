import React from 'react'
import Contact from '../components/Contact/contact'
import Layout from '../components/Layout'
import ContactHeader from '../components/Header/contact_header'
import Helmet from 'react-helmet'
import AOS from 'aos';

const prefix_string = "en";
class ContactPage extends React.Component{
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render(){
    return(
  <Layout>
        <Helmet
          title="WELL – CONTACT | Digital Marketing Agency in Sarajevo City Center"
          meta={[
            { name: 'description', content: 'WELL - Your digital marketing agency in the heart of Sarajevo. We look forward to your inquiry. Tel.: +387 61 787 346' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
          ]} >
        </Helmet>
    <div data-aos='fade-zoom-in'>
        <ContactHeader prefix_string_page={prefix_string}/>
        <Contact prefix_string_page={prefix_string}/>
    </div>
</Layout>
)
        }
      }

export default ContactPage
